/* eslint-disable */
import { NavLink, useLocation } from "react-router-dom";
// chakra imports
import { AbsoluteCenter, Box, Divider, Flex, HStack, Text, useColorModeValue, useDisclosure, Tooltip } from "@chakra-ui/react";
import colors from "../../../theme/foundations/colors";

export function SidebarLinks(props) {
  //   Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");
  // Define active and inactive icon colors
  let activeIconColor = "brand.100"; // replace with your actual color value for active icons
  let inactiveIconColor = "purple"; // replace with your actual color value for inactive icons
  
  let sidebarBgColor = useColorModeValue("gray.200", "brand.100");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const user = JSON.parse(localStorage.getItem("user"))

  const { routes, setOpenSidebar, openSidebar } = props;
  console.log(props, "props")

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  


  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (routes) => {
    console.log("Creating links with routes:", routes);
    return routes.map((route, index) => {
      if (route.category) {
        // Your existing code for categories...
      } else if (!route.under && user?.role && route.layout?.includes(`/${user.role}`)) {
        console.log("Route check details:", {
            name: route.name,
            hasUnder: !route.under,
            userRole: user?.role,
            layoutIncludes: route.layout?.includes(`/${user.role}`),
            layout: route.layout
        });
        return (
          <NavLink key={index} to={route.path} onClick={props.onClose}>
            {route.separator && (
              <Box position='relative' margin='20px 0'>
                <Divider />
                <AbsoluteCenter textTransform={'capitalize'} bg='white' width={'max-content'} padding='0 10px' textAlign={'center'}>
                  {route.separator}
                </AbsoluteCenter>
              </Box>
            )}
            <Tooltip label={route.name} placement="right" hasArrow>
              <Flex alignItems='center' justifyContent='start' ps={"28px"} pb={"9px"} pt={"20px"} backgroundColor={activeRoute(route.path.toLowerCase()) ? sidebarBgColor : ""}>
                <Box color={activeRoute(route.path.toLowerCase()) ? activeIcon : textColor} me='18px'>
                  {route.icon}
                </Box>
                <Text display={openSidebar ? { base: "block" } : { base: "block", xl: "none" }} me='auto' pb={"3px"} color={activeRoute(route.path.toLowerCase()) ? activeColor : textColor} fontWeight={activeRoute(route.path.toLowerCase()) ? "bold" : "normal"}>
                  {route.name}
                </Text>
              </Flex>
            </Tooltip>
          </NavLink>
        );
      }
    });
  };
  
  //  BRAND
  return createLinks(routes);
}

export default SidebarLinks;
