import React, { useState, useEffect } from "react";
import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import colors from "../../../theme/foundations/colors";

const debounce = (func, delay) => {
  let debounceTimer;
  return function() {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

export function SearchBar(props) {
  const [searchQuery, setSearchQuery] = useState("");
  const [timer, setTimer] = useState(null);

  const handleInputChange = (event) => {
    const newQuery = event.target.value;
    setSearchQuery(newQuery);
  
    clearTimeout(timer); // Clear the existing timer
  
    const newTimer = setTimeout(() => {
      if (newQuery.trim()) {
        props.onSearch(newQuery);
      } else {
        props.onSearch(''); // Clear search results if query is empty
      }
    }, 200); // Debounce time in milliseconds
  
    setTimer(newTimer);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, [timer]);
  
  const handleSearch = async () => {
    if (searchQuery.trim()) {
      props.onSearch(searchQuery); // Using props.onSearch here
    }
  };

  // Extracting properties from props
  const { 
    onFocus,
    onBlur,
    background, 
    placeholder, 
    borderRadius, 
    onSearch, // Extract onSearch if you need to use it elsewhere in this component
    ...rest 
  } = props;
  const handleBlur = () => {
    onBlur && onBlur();
    setTimeout(() => {
        setSearchQuery(''); 
        onSearch(''); 
    }, 200); // Delay clearing the results
};

  // Chakra Color Mode
  const searchIconColor = useColorModeValue("gray.700", colors.brand[100]);
  const inputBg = useColorModeValue("secondaryGray.300", colors.brand[600]);
  const inputText = useColorModeValue("gray.700", colors.brand[100]);

  return (
    <InputGroup w="full" {...rest}>
      <InputLeftElement
        children={
          <IconButton
            aria-label="Search"
            onClick={handleSearch}
            bg='inherit'
            borderRadius='inherit'
            _hover='none'
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}
            icon={<SearchIcon color={searchIconColor} w='15px' h='15px' />}
          />
        }
      />
      <Input
        variant='search'
        fontSize='sm'
        bg={background || inputBg}
        color={inputText}
        fontWeight='500'
        _placeholder={{ color: useColorModeValue("gray.400", colors.brand[100]), fontSize: "14px" }}
        borderRadius={borderRadius || "30px"}
        placeholder={placeholder || "Search..."}
        onChange={handleInputChange}
        onFocus={() => onFocus && onFocus()}
        onBlur={handleBlur}
        value={searchQuery}
        onKeyPress={event => {
            if (event.key === 'Enter') {
                handleSearch();
            }
        }}
      />
    </InputGroup>
  );
}
