import React from "react";

// Chakra imports
import { Flex, Heading, useColorModeValue, Image, Box } from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
//import logoImage from "../../../assets/img/auth/logo-white@2x.png";
import logoImageWhite from "../../../assets/img/auth/vidcor-modern-brand-logo-white-wreath.png";
import logoImageBlack from "../../../assets/img/auth/vidcor-modern-brand-logo-black-wreath.png";


export function SidebarBrand(props) {
  const { setOpenSidebar, openSidebar } = props;
  
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");
  let logoImage = useColorModeValue(logoImageBlack, logoImageWhite);
// VIDCOR
  return (
    <Flex direction='column' align='center' justify='center' w="100%">
      {openSidebar ? (
        <Heading mb={5} onClick={() => setOpenSidebar(!openSidebar)} cursor="pointer" userSelect="none">
          VIDCOR
        </Heading>
      ) : (
        <Image src={logoImage} boxSize="40px" mb={5} onClick={() => setOpenSidebar(!openSidebar)} cursor="pointer" userSelect="none" />
      )}
      <HSeparator />
    </Flex>
  );
  }

export default SidebarBrand;
