// Chakra Imports
import {
	Avatar,
	Flex,
	Icon,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	Box,
	useColorModeValue,
} from "@chakra-ui/react";
// Custom Components
import { ItemContent } from "components/menu/ItemContent";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { SidebarResponsive } from "components/sidebar/Sidebar";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
// Assets
import { MdNotificationsNone } from "react-icons/md";
import { FaEthereum } from "react-icons/fa";
import routes from "routes.js";
import { useNavigate } from "react-router-dom";
import { getApi, searchApi } from "services/api";
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";
import colors from "../../theme/foundations/colors";
import { routeIcons } from "../../routes";

export default function HeaderLinks(props) {
	const { secondary } = props;
	// Chakra Color Mode
	const navbarIcon = useColorModeValue("gray.400", colors.brand[100]);
	let menuBg = useColorModeValue("white", colors.brand[700]); //outside search bar color
	const textColor = useColorModeValue("secondaryGray.900", "white");
	const textColorBrand = useColorModeValue("brand.700", "brand.400");
	const textColorBrand2 = useColorModeValue("brand.400", "brand.700");
	const inputText = useColorModeValue("gray.700", colors.brand[100]);
	const ethColor = useColorModeValue("gray.700", "white");
	const inputBg = useColorModeValue("secondaryGray.300", colors.brand[600]);
	const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
	const ethBg = useColorModeValue("secondaryGray.300", "navy.900");
	const ethBox = useColorModeValue("white", "navy.800");
	const shadow = useColorModeValue(
		"14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
		"14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
	);
	// const borderButton = useColorModeValue('secondaryGray.500', 'whiteAlpha.200');

	const [loginUser, setLoginUser] = useState();
	const navigate = useNavigate();
	const [isSearchFocused, setIsSearchFocused] = useState(false);

	const user = loginUser?.firstName + " " + loginUser?.lastName;

	const userId = JSON.parse(localStorage.getItem("user"))?._id;

	const fetchData = async () => {
		let response = await getApi("api/user/view/", userId);
		setLoginUser(response.data);
	};

	useEffect(() => {
		fetchData();
	}, []);

	const [searchResults, setSearchResults] = useState([]);

    const handleSearch = async (query) => {
		console.log("Searching for:", query);
		if (!query.trim()) {
			setSearchResults([]); // Clear results if query is empty
			return;
		}
		try {
			const response = await searchApi(query);
			setSearchResults(response.data);
		} catch (error) {
			console.error("Search error:", error);
			setSearchResults([]);
		}
	};
	
	

    const renderSearchResults = () => {
		return searchResults?.map((result, index) => {
			if (result.deleted) {
				return null; // Skip rendering this result if it is marked as deleted
			}
	
			let displayText, navigatePath, IconComponent, iconColor = "#BB9A65", resultType;
	
			// Check user role and adjust the navigation path
			const userRole = JSON.parse(localStorage.getItem("user"))?.role;
	
			if (result.leadName) {
				// Assuming this is a Lead result
				displayText = result.leadName;
				navigatePath = userRole !== 'admin' ? `/leadView/${result._id}` : `/admin/leadView/${result._id}`;
				IconComponent = routeIcons.Lead;
				resultType = "Lead";
			} else if (result.firstName) {
				// Assuming this is a Contact result
				displayText = `${result.firstName} ${result.lastName}`;
				navigatePath = userRole !== 'admin' ? `/contactView/${result._id}` : `/admin/contactView/${result._id}`;
				IconComponent = routeIcons.Contacts;
				resultType = "Contact";
			} else if (result.propertyAddress) {
				// Assuming this is a Property result
				displayText = result.propertyAddress;
				navigatePath = userRole !== 'admin' ? `/propertyView/${result._id}` : `/admin/propertyView/${result._id}`;
				IconComponent = routeIcons.Property;
				resultType = "Property";
			}
	
			const handleResultClick = () => {
				console.log("Navigating to:", navigatePath);
				navigate(navigatePath);
			};
	
			return (
				<Box
					key={index}
					p="2"
					_hover={{ bg: inputBg, cursor: "pointer" }}
					onClick={handleResultClick}
				>
					 <Flex align="center">
						{IconComponent && React.cloneElement(IconComponent, { color: iconColor, style: { fontSize: '24px', marginRight: '12px', marginLeft: '12px' } })}
						<Flex direction="column" justify="center" ml="1">
							<Text color={textColorBrand2} fontSize="sm" mb="-2">{displayText}</Text>
							<Text fontSize="xs" color={textColorBrand2}>{resultType}</Text>
						</Flex>
					</Flex>
				</Box>
			);
		});
	};
	
	
	 // Adjust the handleBlur function
	 const handleBlur = () => {
        setIsSearchFocused(false);
        if (!searchResults.length) {
            setSearchResults([]); // Clear search results only if there are results
        }
    };
	 // Function to check if there are search results
    const hasSearchResults = searchResults && searchResults.length > 0;

	const [isLogoutScheduled, setIsLogoutScheduled] = useState(false);

	const logOut = (message) => {
		localStorage.clear();
		sessionStorage.clear();
		navigate("/auth");
		if (message) {
			toast.error(message);
		} else {
			toast.success("Log out Successfully");
		}
		setIsLogoutScheduled(true);
	};

	useEffect(() => {
		const token =
			localStorage.getItem("token") || sessionStorage.getItem("token");

		if (token) {
			try {
				const decodedToken = jwtDecode(token);
				const currentTime = Date.now() / 1000; // Convert milliseconds to seconds
				if (decodedToken.exp < currentTime) {
					if (!isLogoutScheduled) {
						logOut("Token has expired");
					}
				} else {
					// Schedule automatic logout when the token expires
					const timeToExpire = (decodedToken.exp - currentTime) * 1000; // Convert seconds to milliseconds
					setTimeout(() => {
						if (!isLogoutScheduled) {
							logOut("Token has expired");
						}
					}, timeToExpire);
				}
			} catch (error) {
				console.error("Error decoding token:", error);
			}
		}
	}, [isLogoutScheduled]);

	return (
		<Flex
			position="relative"
			w={{ sm: "100%", md: "auto" }}
			alignItems="center"
			flexDirection="row"
			bg={menuBg}
			flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
			p="10px"
			borderRadius={hasSearchResults ? "30px 30px 0 0" : "30px"} // Change based on search results
			boxShadow={shadow}
        >
            <SearchBar
                onSearch={handleSearch}
				onFocus={() => setIsSearchFocused(true)}
				onBlur={() => setIsSearchFocused(false)}
                mb={secondary ? { base: "10px", md: "unset" } : "unset"}
                me="10px"
                borderRadius="30px"
            />

            {/* Render search results */}
			{hasSearchResults && (
				<Flex
					direction="column"
					mt="0px" // Adjust this to close any gap. Negative margin might be needed.
					//minH="60px"
					maxH="210px"
					overflowY="auto"
					position="absolute"
					top="100%" // This should position the results right below the input
					left="0"
					right="0"
					w="full" // Make sure this matches the width of the search bar.
					bg={menuBg}
					borderRadius="0 0 30px 30px" // Flat top corners, rounded bottom
					boxShadow="md"
					zIndex="10"
				>
					{renderSearchResults()}
				</Flex>
			)}

			<Flex
				bg={ethBg}
				display={secondary ? "flex" : "none"}
				borderRadius="30px"
				ms="auto"
				p="6px"
				align="center"
				me="6px"
			>
				<Flex
					align="center"
					justify="center"
					bg={ethBox}
					h="29px"
					w="29px"
					borderRadius="30px"
					me="7px"
				>
					<Icon color={ethColor} w="9px" h="14px" as={FaEthereum} />
				</Flex>
				<Text
					w="max-content"
					color={ethColor}
					fontSize="sm"
					fontWeight="700"
					me="6px"
				>
					1,924
					<Text as="span" display={{ base: "none", md: "unset" }}>
						{" "}
						ETH
					</Text>
				</Text>
			</Flex>

			<SidebarResponsive routes={routes} />

			<Menu>
				<MenuButton p="0px">
					<Icon
						mt="6px"
						as={MdNotificationsNone}
						color={navbarIcon}
						w="18px"
						h="18px"
						me="10px"
					/>
				</MenuButton>
				<MenuList
					boxShadow={shadow}
					p="20px"
					borderRadius="20px"
					bg={menuBg}
					border="none"
					mt="22px"
					me={{ base: "30px", md: "unset" }}
					minW={{ base: "unset", md: "400px", xl: "450px" }}
					maxW={{ base: "360px", md: "unset" }}
				>
					<Flex jusitfy="space-between" w="100%" mb="20px">
						<Text fontSize="md" fontWeight="600" color={"textColor"}>
							Notifications
						</Text>
						<Text
							fontSize="sm"
							fontWeight="500"
							color={"red.400"}
							ms="auto"
							cursor="pointer"
						>
							Mark all read
						</Text>
					</Flex>
					<Flex flexDirection="column">
						<MenuItem
							_hover={{ bg: "none" }}
							_focus={{ bg: "none" }}
							px="0"
							borderRadius="8px"
							mb="10px"
						>
							<ItemContent info="Sample Data" aName="Name" />
						</MenuItem>
						<MenuItem
							_hover={{ bg: "none" }}
							_focus={{ bg: "none" }}
							px="0"
							borderRadius="8px"
							mb="10px"
						>
							<ItemContent
								info="Sample Data"
								aName="Name"
							/>
						</MenuItem>
					</Flex>
				</MenuList>
			</Menu>

			{/* <Menu>
				<MenuButton p='0px'>
					<Icon
						mt='6px'
						as={MdInfoOutline}
						color={navbarIcon}
						w='18px'
						h='18px'
						me='10px'
					/>
				</MenuButton>
				<MenuList
					boxShadow={shadow}
					p='20px'
					me={{ base: "30px", md: "unset" }}
					borderRadius='20px'
					bg={menuBg}
					border='none'
					mt='22px'
					minW={{ base: "unset" }}
					maxW={{ base: "360px", md: "unset" }}>
					<Image src={navImage} borderRadius='16px' mb='28px' />
					<Flex flexDirection='column'>
						<Link
							w='100%'
							href='https://horizon-ui.com/pro?ref=horizon-chakra-free'>
							<Button w='100%' h='44px' mb='10px' variant='brand'>
								Buy Horizon UI PRO
							</Button>
						</Link>
						<Link
							w='100%'
							href='https://horizon-ui.com/documentation/docs/introduction?ref=horizon-chakra-free'>
							<Button
								w='100%'
								h='44px'
								mb='10px'
								border='1px solid'
								bg='transparent'
								borderColor={borderButton}>
								See Documentation
							</Button>
						</Link>
						<Link
							w='100%'
							href='https://github.com/horizon-ui/horizon-ui-chakra'>
							<Button
								w='100%'
								h='44px'
								variant='no-hover'
								color={textColor}
								bg='transparent'>
								Try Horizon Free
							</Button>
						</Link>
					</Flex>
				</MenuList>
			</Menu> */}

			{/* <ThemeEditor navbarIcon={navbarIcon} /> */}

			<Menu style={{ zIndex: 1500 }}>
				<MenuButton p="0px">
					<Avatar
						_hover={{ cursor: "pointer" }}
						color="white"
						name={user || "Vidcor"}
						bg={colors.brand[100]}
						size="sm"
						w="40px"
						h="40px"
					/>
				</MenuButton>
				<MenuList
					boxShadow={shadow}
					p="0px"
					mt="10px"
					borderRadius="20px"
					bg={menuBg}
					border="none"
				>
					<Flex w="100%" mb="0px">
						<Text
							ps="20px"
							pt="16px"
							pb="10px"
							w="100%"
							borderBottom="1px solid"
							borderColor={borderColor}
							fontSize="sm"
							fontWeight="700"
							textTransform={"capitalize"}
							color={textColor}
						>
							👋 &nbsp; Hey, {user}
						</Text>
					</Flex>

					<Flex flexDirection="column" p="10px">
						<MenuItem
							_hover={{ bg: "none" }}
							_focus={{ bg: "none" }}
							borderRadius="8px"
							px="14px"
						>
							<Text fontSize="sm" _hover={{color: colors.brand[100]}} onClick={() => navigate(`/admin/`)}>
								Home
							</Text>
						</MenuItem>
						<MenuItem
							_hover={{ bg: "none" }}
							_focus={{ bg: "none" }}
							borderRadius="8px"
							px="14px"
						>
							<Text
								fontSize="sm"
								_hover={{ color: colors.brand[100]}}
								onClick={() =>
									navigate(
										JSON.parse(localStorage.getItem("user"))?.role === "admin"
											? `/admin/userView/${JSON.parse(localStorage.getItem("user"))?._id
											}`
											: `/userView/${JSON.parse(localStorage.getItem("user"))?._id
											}`
									)
								}
							>
								Profile Settings
							</Text>
						</MenuItem>
						{/*<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px">
							<Text fontSize="sm">Newsletter Settings</Text>
						</MenuItem> */}
						<MenuItem
							_hover={{ bg: "none" }}
							onClick={logOut}
							_focus={{ bg: "none" }}
							color="red.400"
							borderRadius="8px"
							px="14px"
						>
							<Text fontSize="sm">Log out</Text>
						</MenuItem>
					</Flex>
				</MenuList>
			</Menu>
		</Flex>
	);
}

HeaderLinks.propTypes = {
	variant: PropTypes.string,
	fixed: PropTypes.bool,
	secondary: PropTypes.bool,
	onOpen: PropTypes.func,
};
