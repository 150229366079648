import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import UserLayout from 'layouts/user';
import { ChakraProvider, useColorMode } from '@chakra-ui/react';
import theme from './theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// New ThemeAwareLayout component
function ThemeAwareLayout({ children }) {
  const { colorMode } = useColorMode();

  useEffect(() => {
    // Update theme-color meta tag when color mode changes
    const themeColor = colorMode === 'dark' ? '#000000' : '#FFFFFF';
    const themeColorMetaTag = document.querySelector('meta[name="theme-color"]');
    if (themeColorMetaTag) {
      themeColorMetaTag.setAttribute('content', themeColor);
    }
  }, [colorMode]);

  return <>{children}</>;
}

function App() {
    const token = localStorage.getItem("token") || sessionStorage.getItem("token");
    console.log(theme.colors.brand[100]);
    const user = JSON.parse(localStorage.getItem("user"))
    useNavigate()

    useEffect(() => {
        // Log initial title
        console.log('Initial title:', document.title);
        
        // Try to set it immediately
        document.title = 'Vidcor CRM';
        
        // And also after a short delay to ensure DOM is fully loaded
        setTimeout(() => {
            document.title = 'Vidcor CRM';
            console.log('Title after timeout:', document.title);
        }, 100);

        // Watch for title changes
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (document.title !== 'Vidcor CRM') {
                    console.log('Title changed to:', document.title);
                    console.trace('Title change stack trace');
                }
            });
        });

        const titleElement = document.querySelector('title');
        if (titleElement) {
            observer.observe(titleElement, {
                subtree: true,
                characterData: true,
                childList: true
            });
        }

        return () => observer.disconnect();
    }, []);

    return (
        <>
            <ToastContainer />
            <Routes>
                {token && user?.role ? (
                    user?.role == 'user' ?
                        <Route path="/*" element={<UserLayout />} />
                        : user?.role === 'admin' ?
                            <Route path="/*" element={<AdminLayout />}  />
                            : ''
                ) : (
                    <Route path="/*" element={<AuthLayout />} />
                )}
            </Routes>
        </>
    );
}

ReactDOM.render(
    <ChakraProvider theme={theme}>
        <React.StrictMode>
            <ThemeEditorProvider>
                <Router>
                    <ThemeAwareLayout>
                        <App />
                    </ThemeAwareLayout>
                </Router>
            </ThemeEditorProvider>
        </React.StrictMode>
    </ChakraProvider>
    , document.getElementById('root')
);